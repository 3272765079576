<template>
  <ValidationObserver ref="observer" tag="div" v-slot="{ handleSubmit }">
    <form @submit.prevent="handleSubmit(validate)" class="w-300">
      <div class="w-full">
        <ValidationProvider v-slot="{ errors }" tag="div" rules="required" class="mb-2">
          <BaseInput type="text" label="Name" name="name" v-model="payload.name" />
          <p class="text-sm text-red-600" v-if="errors[0]">{{ errors[0] }}</p>
        </ValidationProvider>

        <ValidationProvider v-slot="{ errors }" tag="div" rules="" class="mb-2">
          <BaseTextarea type="text" label="Description" name="description" v-model="payload.description" />
          <p class="text-sm text-red-600" v-if="errors[0]">{{ errors[0] }}</p>
        </ValidationProvider>

        <hr class="my-6"/>

        <h2 class="my-4">Levels</h2>
        <div class="" v-for="(level, index) in payload.levels" :key="index">
            <BaseSelect name="level" label="Level" :options="levelTypes" v-model="level.level"/>
            
            <BaseTextarea label="Question" name="question" v-model="level.question" />
            <BaseTextarea label="Answer" name="description" v-model="level.answer" />
            <BaseBtn type="submit" icon="fa-solid fa-minus" @click="removeLevel(index)" :outlined="true" class="mt-1" text="Delete level" />
            <hr class="my-6"/>
        </div>
        <BaseBtn @click="addLevel" icon="plus" type="button" class="" :text="'Add Question & Answer'" />
      </div>

      <BaseBtn type="submit" class="mt-8" :text="payload.id ? 'Update' : 'Add'" />
      <FlashMessage :message="message" :error="error" />
    </form>
  </ValidationObserver>
</template>

<script>
import {
  extend,
  ValidationObserver,
  ValidationProvider,
  //setInteractionMode,
} from "vee-validate";
import { required } from "vee-validate/dist/rules";

extend("required", {
  ...required,
  message: "This field is required",
});

import { mapGetters } from "vuex";
import { getError } from "@/utils/helpers";
import BaseBtn from "@/components/BaseBtn";
import BaseTextarea from "@/components/BaseTextarea";
import BaseInput from "@/components/BaseInput";
import SkillService from "@/services/SkillService";
import FlashMessage from "@/components/FlashMessage";
import BaseSelect from "@/components/BaseSelect";


export default {
  name: "AuthUserForm",
  components: {
    BaseBtn,
    BaseInput,
    BaseTextarea,
    BaseSelect,
    FlashMessage,
    ValidationProvider,
    ValidationObserver,
  },
  props: {
    attributes: {
      type: Object,
      default() {
        return {};
      },
    },
  },
  data() {
    return {
      payload: {
        name: "",
        levels: [],
      },
      levelTypes: [{key: 'Bronze', value:"Bronze"}, {key: 'Silver', value:"Silver"}, {key: 'Gold', value:"Gold"}],
      error: null,
      message: null,
    };
  },
  computed: {
    ...mapGetters("skill", ["add", "update"]),
  },
  methods: {
    validate() {
      this.error = null;
      this.message = null;
      console.log("this.payload", this.payload);

      if (this.payload.id) {
        SkillService.update(this.payload)
          .then(() => {
            this.message = "Skill updated.";
            this.$parent.close();
            this.$eventBus.$emit("skills:updated", true);
          })
          .catch((error) => (this.error = getError(error)));
      } else {
        SkillService.add(this.payload)
          .then(() => {
            this.message = "Skill added.";
            this.$parent.close();
            this.$eventBus.$emit("skills:added", true);
          })
          .catch((error) => (this.error = getError(error)));
      }
    },
    addLevel() {
        this.payload.levels.push({level: '', question: null, answer: '', topic_id: this.payload.topic_id})
    },
    removeLevel(index) {
      this.payload.levels.splice(index, 1);
    },
  },
  mounted() {

    if (this.attributes.topic_id) {
      this.payload.topic_id = this.attributes.topic_id;
    }

    if (this.attributes.payload) {
      this.payload = this.attributes.payload;

      console.log('this.payload', this.payload);
    }
  },
};
</script>
