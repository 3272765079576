import * as API from "@/services/API";

export default {
  getSkill(id) {
    return API.apiClient.get(`/skills/${id}`);
  },
  getSkills() {
    return API.apiClient.get(`/skills`);
  },
  add(payload) {
    return API.apiClient.post(`/skills`, payload);
  },
  update(payload) {
    return API.apiClient.put(`/skills/${payload.id}`, payload);
  },
};
